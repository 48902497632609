<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'salesSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content bg-white border0">
          <!-- sales order canceled status is 3 and sales canceled status is 2 -->
          <div class="cancel-watermark" v-if="details.status == 2">
            <div class="water-mark-content">Cancelled</div>
          </div>

          <div class="modal-header no-print">
            <div class="row">
              <div class="col-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  <span v-if="details.order_id"
                    >Sales Order Slip - {{ details.prefix
                    }}{{ details.order_id }}</span
                  >
                  <span v-else-if="details.bill_no"
                    >Sales Slip - {{ details.prefix
                    }}{{ details.bill_no }}</span
                  >
                </h5>
              </div>

              <div class="col-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i
                    class="fas fa-print"
                    @click="print(details.id)"
                    title="Print Slip"
                  ></i>
                </div>
                <!-- <div class="bill-head-icon mr-3">
                  <i class="fas fa-download" title="Download Slip"></i>
                </div> -->
                <div class="bill-head-icon mr-1" @click="closeSlipModal()">
                  <i class="fas fa-times" title="Close Slip"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center" v-if="slipSettings.organization">
              <p class="font-xxxl mb-0">{{ slipSettings.organization.name }}</p>
              <p class="mb-0 font-print-xxs">
                {{ details.branch.address }}
                <br />
                Tel No: {{ details.branch.phone }} / {{ details.branch.email }}
                <br />
                PAN: {{ slipSettings.organization.pan_vat }}
              </p>
              <hr class="print-hr-margin" />
            </div>
            <!-- col -->
            <!-- <div class="text-center">Tax Invoice</div> -->
            <!-- <hr> -->
            <div class="row">
              <div
                class="
                  col-sm-4 col-lg-4
                  mg-t-sm-40 mg-t-10 mg-sm-t-0
                  order-2 order-sm-0
                "
                v-if="details.customer"
              >
                <label class="content-label mb-1">Billed To</label>
                <p class="mg-b-0 font-print-xxs">
                  NAME : {{ details.customer.name }}
                </p>
                <p class="mg-b-0 font-print-xxs">
                  ADDRESS : {{ details.customer.address }}
                </p>
                <p class="mg-b-0 font-print-xxs">
                  PAN : {{ details.customer.pan_vat_no }}
                </p>
                <span v-if="authData.general_setting.enable_vehicle_detail==1">
                  <p class="mg-b-0 font-print-xxs">Engine No. - <span v-if="details.customer.vehicle_detail"> {{details.customer.vehicle_detail.engine_no}}</span></p>
                  <p class="mg-b-0 font-print-xxs">Chassis No. - <span v-if="details.customer.vehicle_detail"> {{details.customer.vehicle_detail.chasis_no}}</span></p>
                  <p class="mg-b-0 font-print-xxs">Vehicle No. - <span v-if="details.customer.vehicle_detail"> {{details.customer.vehicle_detail.vehicle_no}}</span></p>
                  <p class="mg-b-0 font-print-xxs">Model No. - <span v-if="details.customer.vehicle_detail"> {{details.customer.vehicle_detail.model_no}}</span></p>
                </span>
                <!-- This section is only for BDG clinet -->
                <!-- <p class="mg-b-0 font-print-xxs" v-if="details.customer.phone">
                  Contact No. : {{ details.customer.phone }}
                </p> -->
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4">
                <p class="text-center font-l m-0">
                  <span v-if="details.order_id">Sales Order Slip</span>
                  <span v-else-if="details.vat_amount > 0"
                    >ESTIMATE INVOICE</span
                  >
                  <span v-else-if="details.bill_no">ESTIMATE INVOICE</span>
                </p>
                <hr class="print-sm-hr print-hr-margin" />
              </div>
              <div
                class="col-sm-4 col-lg-4 mg-t-sm-15 mg-t-10 order-1 order-sm-0"
              >
                <p class="mg-b-0 text-sm-right font-print-xxs">
                  Bill No :
                  <span v-if="details.bill_no"
                    >{{ details.prefix }}{{ details.bill_no }}</span
                  >
                  <span v-if="details.order_id"
                    >{{ details.prefix }}{{ details.order_id }}</span
                  >
                  <span v-if="details.shipping_type == 1">- Export</span>
                </p>
                <p class="mg-b-0 text-sm-right font-print-xxs">
                  DATE : {{ details.date }}
                </p>
                <p
                  class="mg-b-0 text-sm-right font-print-xxs"
                  v-if="details.bill_print_count > 0"
                >
                  Duplicate Copy {{ details.bill_print_count }}
                </p>
                <p
                  class="mg-b-0 text-sm-right font-print-xxs"
                  v-if="details.table_id"
                >
                  Table : {{ details.table.table }}
                </p>
                <p
                  class="mg-b-0 text-sm-right font-print-xxs"
                  v-if="details.chalan_no"
                >
                  Chalan No : {{ details.chalan_no }}
                </p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
            <div class="mt-2">
              <table class="table table3 font-print-xxs">
                <thead class="bg-none">
                  <tr class="bg-none">
                    <th scope class="wd-5p">S.N.</th>
                    <th scope class="wd-25p">Particular</th>
                    <th scope class="wd-15p" v-if="authData.item_setting.extra_detail ==1">Extra Details</th>
                    <th scope class="wd-20p">Qty</th>
                    <th scope class="wd-10p">Rate</th>
                    <th scope class="wd-10p">Disc.</th>
                    <th scope class="wd-15p tx-right">Total</th>
                  </tr>
                </thead>
                <tbody class="hover-none">
                  <tr v-for="(item, index) in details.items" :key="index">
                    <th scope="row">{{ ++index }}</th>
                    <td>{{ item.item.name }}</td>
                    <td v-if="authData.item_setting.extra_detail ==1">
                      <div v-if="item.item.extra_details && item.item.extra_details.length > 0">
                        <div
                          v-for="detail in item.item.extra_details"
                          :key="detail.id"
                        >
                          {{ detail.title }} - {{ detail.value }}<br />
                        </div>
                      </div>
                      <div v-else>-</div>
                    </td>
                     <td>
                      {{ item.quantity }}
                      <span class>{{ item.item.unit_type.title }}</span>
                    </td>
                    <td>{{ parseDigitForSlip(item.sales_rate) }}</td>
                    <td>
                      {{ parseDigitForSlip(item.sales_discount_amount) }}
                    </td>
                    <td class="tx-right">
                      {{ parseDigitForSlip(item.total) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="
                  col-sm-7 col-lg-7
                  print-sm-12 print-lg-4
                  order-2 order-sm-0
                  mg-t-5 mg-sm-t-0
                "
              >
                <!-- Amount-in-word -->
                <div class="footer_detail mt-0 mb-0 mb-sm-2 p-2">
                  <label class="content-label mb-1">Amount In Word</label>
                  <p
                    class="mg-b-0 font-print-xxs"
                    v-if="details.bill_amount > 0"
                  >
                    {{ toWord(details.bill_amount) }} Only.
                  </p>
                </div>
                <div
                  class="table-responsive no-print-sm mt-2 mt-md-2"
                  v-if="details.paid_amount > 0"
                >
                  <table
                    class="
                      table table-bordered
                      mg-b-0
                      table_lr_border
                      color-secondary
                    "
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">Payment Type</th>
                        <th scope="col" class="border-right_1">Ref.</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ details.payment_methods.title }}
                          <span v-if="details.bank_detail"
                            >- {{ details.bank_detail.bank }}</span
                          >
                        </td>
                        <td v-if="details.txn_id">{{ details.txn_id }}</td>
                        <td v-else class="text-center">- -</td>
                        <td>{{ parseDigitForSlip(details.paid_amount) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Shipping-detail -->
                <div
                  class="footer_detail mt-2 mb-0 p-2"
                  v-if="details.vehicle || details.driver || details.contact"
                >
                  <label class="content-label mb-1">Shipping Detail</label>
                  <p class="mg-b-0 font-print-xxs" v-if="details.vehicle">
                    Vehicle No : {{ details.vehicle }}
                  </p>
                  <p class="mg-b-0 font-print-xxs" v-if="details.driver">
                    Driver Name : {{ details.driver }}
                  </p>
                  <p class="mg-b-0 font-print-xxs" v-if="details.contact">
                    Contact No : {{ details.contact }}
                  </p>
                </div>
                <!-- Shipping-detail -->
              </div>
              <!-- col -->
              <div
                class="
                  col-sm-4 col-lg-4
                  print-sm-12 print-lg-4
                  order-1 order-sm-0
                  color-secondary
                  mb-0
                  mt-0
                  font-print-xxs
                "
              >
                <ul class="list-unstyled lh-6 mb-0 print-sm-mg">
                  <li class="d-flex justify-content-between">
                    <span>Sub-Total</span>
                    <span>{{ parseDigitForSlip(details.total) }}</span>
                  </li>
                  <li
                    class="d-flex justify-content-between"
                    v-for="itemExtra in vatAbleExtraFields"
                    :key="itemExtra.slug"
                  >
                    <span>{{
                      itemExtra.slug
                        | makeTitle(
                          itemExtra.slug,
                          slipSettings.sales_extra_fields
                        )
                    }}</span>
                    <span>{{ parseDigitForSlip(itemExtra.amount) }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>VAT able Amount</span>
                    <span>{{ parseDigitForSlip(details.vatable_amount) }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>VAT (13%)</span>
                    <span>{{ parseDigitForSlip(details.vat_amount) }}</span>
                  </li>
                  <li
                    class="d-flex justify-content-between"
                    v-for="(itemExtra, index) in nonVatAbleExtraFields"
                    :key="index"
                  >
                    <span>{{
                      itemExtra.slug
                        | makeTitle(
                          itemExtra.slug,
                          slipSettings.sales_extra_fields
                        )
                    }}</span>
                    <span>{{ parseDigitForSlip(itemExtra.amount) }}</span>
                  </li>

                  <li class="d-flex justify-content-between">
                    <span>Total</span>
                    <span>{{ parseDigitForSlip(details.bill_amount) }}</span>
                  </li>
                  <div v-if="details.advance_amount > 0">
                    <li class="d-flex justify-content-between">
                      <span>Advance Paid</span>
                      <span>{{
                        parseDigitForSlip(details.advance_amount)
                      }}</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      <span>Total Current Paid</span>
                      <span>{{ parseDigitForSlip(details.paid_amount) }}</span>
                    </li>
                  </div>
                  <div v-else>
                    <li class="d-flex justify-content-between">
                      <span>Total Paid</span>
                      <span>{{ parseDigitForSlip(details.paid_amount) }}</span>
                    </li>
                  </div>
                  <li class="d-flex justify-content-between">
                    <span>Total Due</span>
                    <span>{{ parseDigitForSlip(details.due_amount) }}</span>
                  </li>
                </ul>
              </div>
              <!-- col -->
            </div>
            <hr />

            <!-- Footer Container -->
            <div class="print-mb-3">
              <div class="row">
                <div class="col-sm-9">
                  <div class="footer_note" v-if="details.note">
                    <label class="content-label mb-1 no-print-sm">Note</label>
                    <p class="mb-0 text-center text-sm-left font-print-xxs">
                      {{ details.note }}
                    </p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <p
                    class="mb-0 mt-p-2 text-right font-print-xxs"
                    v-if="slipSettings.sales_setting"
                  >
                    <span v-if="slipSettings.sales_setting.user_name == 1">
                      User: {{ details.user_details.name }}
                    </span>
                  </p>
                </div>
              </div>
              <div v-if="details.status == 2">
                <hr />
                <div class="footer_note">
                  <label class="content-label mb-1">Cancelation Details</label>
                  <p class="mg-b-0 font-print-xxs">
                    Cancellation Date : {{ details.date }}
                  </p>
                  <p class="mg-b-0 font-print-xxs">
                    Cancellation User:
                    <span
                      v-if="details.status == 2 && !details.canceled_user"
                      >{{ loggedInUserName }}</span
                    >
                    <span v-else>{{ details.canceled_user.name }}</span>
                  </p>
                  <p
                    class="mb-2 text-sm-left font-print-xxs"
                    v-if="details.remarks"
                  >
                    Remark: {{ details.remarks }}
                  </p>
                </div>
                <hr class="print-display-only-block" />
              </div>
            </div>

            <!-- <div class="footer_note">
              <label class="content-label mb-1 no-print-sm">Note</label>
              <p class="mb-2 text-center text-sm-left font-print-xxs">{{ details.note }}</p>
            </div> -->
            <!-- <p class="text-right font-print-xxs" v-if="slipSettings.sales_setting">
                <span
                  v-if="slipSettings.sales_setting.user_name==1"
                >User: {{ details.user_details.name }}</span>
              </p> -->
          </div>
          <!-- <div v-if="details.status == 2">
            <hr />
            <div class="footer_note">
              <label class="content-label mb-1">Cancelation Detail</label>
              <p class="mg-b-0 font-print-xxs">Cancellation Date : {{ details.date }}</p>
              <p class="mg-b-0 font-print-xxs">
                Cancellation User :
        
                <span v-if="details.status == 2 && !details.canceled_user">{{ loggedInUserName}}</span>
                <span v-else>{{ details.canceled_user.name }}</span>
              </p>
              <p
                class="mb-2 text-sm-left font-print-xxs"
                v-if="details.remarks"
              >Remark: {{ details.remarks }}</p>
            </div>
            <hr class="print-display-only-block" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "http://localhost:8080/assets/css/cassie.css",
    "http://localhost:8080/assets/css/bootstrap.css",
    "http://localhost:8080/assets/css/changes.css",
  ],
};
Vue.use(options);
export default {
  data() {
    return {
      details: [],
      loggedInUserName: "",
    };
  },
  computed: {
    ...mapGetters([
      "dataLists",
      "dataLists9",
      "dataId",
      "modalId",
      "modalMode",
      "dataLists6",
      "authData"
    ]),
    ...mapGetters("sales", ["dataSets", "slipSettings"]),

    vatAbleExtraFields() {
      return this.details.extra_fields.filter((el) => {
        return el.vat == 1;
      });
    },
    nonVatAbleExtraFields() {
      return this.details.extra_fields.filter((el) => {
        return el.vat == 0;
      });
    },
  },
  filters: {
    makeTitle(value, slug, slipSettings) {
      var words = [];
      slipSettings.filter((ss) => {
        if (ss.slug == slug) {
          words.push(ss.title);
        }
      });

      return words.join(" ");
    },
  },
  mounted() {
    //what this does actaully is .. it makes no need to refresh manage return page to show the canceled user name.
    let user = JSON.parse(localStorage.getItem("authData"));
    this.loggedInUserName = user.user.name;
  },
  methods: {
    print(id) {
      //firing the event to parent component
      this.$emit("printSlip", id);
    },
    closeSlipModal() {
      this.$store.dispatch("modalClose");
      if (this.$route.name == "edit-purchase-by-id") {
        this.$router.push({ name: "purchase-listing" });
      }
      if (this.$route.name == "sales-edit") {
        this.$router.push({ name: "list-sales" });
      }
    },
  },

  watch: {
    dataId(value) {
      this.details = this.dataSets.find(function (data) {
        return data.id == value;
      });
        console.log(this.details);
    },
  },
};
</script>